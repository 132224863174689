<template>
  <!-- 包车套餐管理 -->
  <div class="carterPackageManage">
    <el-button
      v-if="listFind('添加')"
      type="primary"
      size="small"
      @click="addBtnFun"
      >添加</el-button
    >
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      operation-width="150"
    >
      <template slot-scope="scope">
        <div>
          <template>
            <el-button
              v-if="listFind('编辑')"
              type="text"
              size="small"
              sort="primary"
              @click="updateBtn(scope.scopeRow)"
              >编辑</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('查看')"
              type="text"
              size="small"
              sort="primary"
              @click="seeFun(scope.scopeRow)"
              >查看</el-button
            >
          </template>
          <template>
            <el-button
              v-if="listFind('删除')"
              type="text"
              size="small"
              sort="danger"
              @click="deleteFun(scope.scopeRow)"
              >删除</el-button
            >
          </template>
        </div>
      </template>
    </Table>
    <charterPopup
      v-if="show"
      :header-text="headerText"
      :disabled="disabled"
      @confirmFun="confirmFun"
      @closePopupFun="
        () => {
          this.show = false;
          this.clearForm();
        }
      "
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="套餐名称：" prop="name">
          <el-input
            v-model="Form.name"
            maxlength="8"
            :disabled="disabled"
            placeholder="请输入套餐名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="套餐说明：" prop="description">
          <el-input
            v-model="Form.description"
            maxlength="10"
            :disabled="disabled"
            placeholder="请输入套餐说明"
          ></el-input>
        </el-form-item>
        <el-form-item label="时长(小时)：" prop="duration">
          <el-input
            v-model="Form.duration"
            :disabled="disabled"
            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
            placeholder="请输入时长(小时)"
          ></el-input>
        </el-form-item>
        <el-form-item label="里程(公里)：" prop="mileage">
          <el-input
            v-model="Form.mileage"
            :disabled="disabled"
            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
            placeholder="请输入里程(公里)"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否启用：">
          <el-switch
            v-model="switchOnoff"
            :disabled="disabled"
            active-color="#13ce66"
            inactive-color="#E3E3E3"
          ></el-switch>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  carPackageAddAPI,
  carPackageRenderAPI,
  carPackageDeleteAPI,
  carPackageUpdateAPI,
  drivingRegulationAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      disabled: "",
      popupStatus: "",
      switchOnoff: false,
      Form: {
        name: "",
        description: "",
        duration: "",
        mileage: "",
        status: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入套餐名称", trigger: "blur" }],
        description: [
          { required: true, message: "请输入套餐说明", trigger: "blur" },
        ],
        duration: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value == "") {
                callback("请输入时长");
              } else {
                if (value > this.timeRuleValue) {
                  callback(`时长数最大不得超过${this.timeRuleValue}`);
                } else {
                  callback();
                }
              }
            },
          },
        ],
        mileage: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value == "") {
                callback("请输入里程");
              } else {
                if (value > this.Form.duration * this.TopSpeedRule) {
                  callback(
                    `里程数最大不得超过${
                      this.Form.duration * this.TopSpeedRule
                    }`
                  );
                } else {
                  callback();
                }
              }
            },
          },
        ],
      },
      headerText: "",
      show: false,
      tableData: [],
      titleName: [
        {
          title: "套餐名称",
          props: "name",
        },
        {
          title: "套餐说明",
          props: "description",
        },
        {
          title: "时长（小时）",
          props: "duration",
        },
        {
          title: "里程（公里）",
          props: "mileage",
        },
        {
          title: "启用",
          props: "status",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.status === 0 ? true : false,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  console.log(params);
                  let obj = {
                    description: params.description,
                    duration: params.duration,
                    id: params.id,
                    mileage: params.mileage,
                    name: params.name,
                    status: params.status == 1 ? 0 : 1,
                  };
                  this.updateFun(obj);
                },
              },
            });
          },
        },
      ],
      timeRuleValue: 0,
      TopSpeedRule: 0, //最高时速规则
    };
  },
  mounted() {
    this.renderData();
    drivingRegulationAPI({ channelNo: this.$configs.channelNo }).then((res) => {
      console.log(res);
      if (res.code == "SUCCESS") {
        let v = res.data[0].regulationValue.split("~");
        this.timeRuleValue =
          parseInt(v[1].split(":")[0]) - parseInt(v[0].split(":")[0]);
        console.log(this.timeRuleValue);
        this.TopSpeedRule = res.data[res.data.length - 1].regulationValue;
      }
    });
  },
  methods: {
    clearForm() {
      this.switchOnoff = false;
      this.Form = {
        name: "",
        description: "",
        duration: "",
        mileage: "",
        status: 1,
      };
    },
    // 点击查看按钮
    seeFun({ id, description, duration, mileage, name, status }) {
      this.headerText = "查看";
      this.disabled = true;
      this.show = true;
      this.Form = { id, description, duration, mileage, name, status };
      if (status === 0) {
        this.switchOnoff = true;
      } else {
        this.switchOnoff = false;
      }
    },
    // 点击添加按钮
    addBtnFun() {
      this.disabled = false;
      this.popupStatus = "add";
      this.show = true;
      this.headerText = "添加";
    },
    // 点击编辑按钮
    updateBtn({ id, description, duration, mileage, name, status }) {
      this.headerText = "编辑";
      this.disabled = false;
      this.popupStatus = "update";
      this.Form = { id, description, duration, mileage, name, status };
      if (status === 0) {
        this.switchOnoff = true;
      } else {
        this.switchOnoff = false;
      }
      this.show = true;
    },
    // 更新编辑数据
    updateFun(obj) {
      carPackageUpdateAPI(obj).then((res) => {
        if (res.code == "SUCCESS") {
          this.$message.success("修改成功");
          this.renderData();
        }
      });
    },
    deleteFun(params) {
      const h = this.$createElement;
      this.$msgbox({
        title: "提示",
        type: "warning",
        message: h("p", null, [h("span", null, "确认删除？")]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          carPackageDeleteAPI({ id: params.id }).then((res) => {
            if (res.code == "SUCCESS") {
              this.$message.success("删除成功");
              this.renderData();
            }
          });
        })
        .catch(() => {});
    },
    // 表格数据渲染
    renderData() {
      carPackageRenderAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data;
        }
      });
    },
    // 确定按钮回调
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          if (this.switchOnoff) {
            this.Form.status = 0;
          } else {
            this.Form.status = 1;
          }
          switch (this.popupStatus) {
            case "add":
              // 新增接口
              carPackageAddAPI(this.Form).then((res) => {
                if (res.code == "SUCCESS") {
                  this.$message.success("新增成功");
                  this.clearForm();
                  this.renderData();
                }
              });
              break;
            case "update":
              this.updateFun(this.Form);
              break;
          }
          this.switchOnoff = false;
          this.show = false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.carterPackageManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
}
.Table {
  margin-top: 14px;
}
</style>
